.body {
    margin-top: 1%;
    margin-left: 1%;
    margin-right: 1%;
    /* text-align: start; */
    align-items: center;
    /* display: flex; */
    justify-content: center;
    /* margin-bottom: 5%; */
    /* background-color: #61dafb; */
}