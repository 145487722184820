@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800');
/* @import url('https://fonts.googleapis.com/css?family=Inter:400,500,600,700,800'); */

* {
  box-sizing: border-box;
}

input[type=checkbox] {
  /* background-color: gainsboro !important; */
  outline: 3px solid rgb(255, 251, 251) !important;
}

input[type=radio] {
  outline: 3px solid rgb(255, 251, 251) !important;
}

/* Dark mode */
@media (prefers-color-scheme: dark) {
  input[type=checkbox] {
    /* background-color: gainsboro !important; */
    outline: 2px solid rgb(255, 255, 255) !important;
  }
}

@media only screen and (max-width: 575px) {
  .mt-1 {
    padding-bottom: 60px;
  }

  #free-offer-button {

    /* background-color: red; */
    /* padding: 0px; */
    /* margin-left: 10px; */
    /* padding-bottom: 10px; */
    /* margin: 10%; */
    position: fixed;
    /* right: 0px; */
    /* left: 2px; */
    /* white-space: nowrap; */

    /* position:fixed; */
    /* text-align: center!important; */
    /* padding: 1%; */

    bottom: 15px;
    /* left: 50%; */
    /* margin-left: 180px; */
    /* position: fixed; */
    /* margin-right: 20px !important; */
    /* flex-shrink: 0 !important; */
    width: 98% !important;
    height: 25;
    right: 1%;
    /* top: 50%; */
    /* -ms-transform: translateY(-50%);
    transform: translateY(-50%); */
    /* font-size:14px;
    font-weight:normal;
    border:1px solid #d97b6c; */
  }
}

body {
  background: #ffffff !important;
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: 'Fira Sans', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-weight: 500;
  font-family: 'Fira Sans', sans-serif;
}

body,
html,
.App,
#root,
.auth-wrapper {
  width: 100%;
  height: 100%;
}

.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all .3s;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}

html, body, .body, .container-fluid {
  margin: 0px !important;
  padding: 0px !important;
}